<template>
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-7">
      <h5 class="blue-text font-weight-bold">タイムスタンプ一覧</h5>
    </div>
    <div class="col-12 col-md-12 col-lg-12 col-xl-5">
      <div id="table-top-ation">
        <div class="search">
          <div class="icon">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search' }" />
          </div>
          <input
            type="text"
            placeholder="検索"
            v-model="searchId"
            v-on:keyup="searchData"
          />
        </div>
      </div>
    </div>
    <div class="col-12 mt-4">
      <div v-if="timeStampData">
        <div v-if="Object.keys(timeStampData).length > 0">
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr v-for="(el, i) in timeStampData" :key="i">
                  <td class="align-middle text-center" width="150px">
                    <p class="m-0 ellipsis-1">{{ i }}</p>
                  </td>
                  <td v-for="(col, j) in el" :key="j" class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{col.status_name}}：{{ formatDate(col.created_at) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
            <img src="/img/icons/ico-info.svg" alt="ico-info" />
            <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
          </b-card>
        </div>
      </div>
      <div class="w-100" v-else>
        <div class="table-responsive">
          <table class="table w-100" striped hover>
            <tbody>
              <tr v-for="(el, i) in 15" :key="i">
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { adm_stagingService_ChangeTime } from '../../services/admin/staging';
import moment from 'moment';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - TimeLine',
    };
  },

  data() {
    return {
      searchId: '',
      timeStampData: null,
      debounce: null,
    };
  },
  watch: {
    $route() {
      this.getResults();
    },
  },
  mounted() {
    this.getResults();
  },
  methods: {
    formatDate(date) {
      if (date == null) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    getResults() {
      let accessToken = localStorage.getItem('_accessToken');

      this.timeStampData = null;

      adm_stagingService_ChangeTime({
        token: accessToken,
        searchId: this.searchId ? this.searchId : '',
      })
        .then((response) => {
          let data = response.data.result;

          this.timeStampData = data;
        })
        .catch((error) => {
          console.log(error);
        });

    },
    searchData(e) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getResults();
      }, 600);
    },
  },
};
</script>

<style scoped>
/* Init Style */
.table thead {
  background: #fff;
}

.table thead th {
  border-bottom: 0;
  color: #000;
  font-weight: bold !important;
  text-align: center;
  border-top: 0;
  font-weight: 400;
}

.table tbody td {
  border: none;
  cursor: pointer;
}

.table tbody tr {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table tbody tr:hover {
  background: #d1d1d1;
}

/* Custom Style */
#table-top-ation {
  display: flex;
}

@media only screen and (max-width: 767px) {
  #table-top-ation {
    display: block;
  }
}

#table-top-ation .search {
  display: flex;
  margin-right: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .search {
    margin-bottom: 14px;
  }
}

#table-top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#table-top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#table-top-ation .search input:focus {
  outline: none;
}

#table-top-ation .add-user {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .add-user {
    width: 100%;
  }
}
</style>
